import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class ExpandableBox extends PureComponent {
  static displayName = "Content.ExpandableBox.Wrapper";

  static propTypes = {
    heading: PropTypes.string,
    teaser: PropTypes.string,
    body: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false, // Track whether the box is open or closed
      isFadingOut: false, // Track fade-out animation
      contentHeight: "0px", // Height for animations
    };
    this.contentRef = React.createRef();
  }

  handleToggle = () => {
    const { isOpen, isFadingOut } = this.state;

    if (isOpen) {
      this.setState({ isFadingOut: true });

      setTimeout(() => {
        this.setState({
          isOpen: false,
          isFadingOut: false,
          contentHeight: "0px",
        });
      }, 1000); // Match fade-out duration in CSS
    } else {
      if (this.contentRef.current) {
        const contentHeight = `${this.contentRef.current.scrollHeight}px`;

        this.setState({ isOpen: true, contentHeight }, () => {
          setTimeout(() => {
            if (this.contentRef.current) {
              this.contentRef.current.style.height = "auto";
            }
          }, 600); // Match height transition duration
        });
      }
    }
  };

  render() {
    const { heading, teaser, body, className } = this.props;
    const { isOpen, isFadingOut, contentHeight } = this.state;

    return (
      <div className={`fold-box ${className} ${isOpen ? "open" : "closed"}`}>
        <button
          className="header"
          aria-expanded={isOpen}
          onClick={this.handleToggle}
          onKeyDown={this.handleKeyDown}
        >
          {heading && <h4>{heading}</h4>}
          <div className={`toggle ${isOpen ? "open" : "closed"}`} />
        </button>

        <div className="teaser" aria-hidden={isOpen}>
          <p>{teaser}</p>
        </div>

        <div
          className="content-rte rel"
          ref={this.contentRef}
          style={{
            height: isFadingOut
              ? `${this.contentRef.current?.scrollHeight}px`
              : contentHeight,
            overflow: "hidden",
            transition: "height 0.6s ease-in-out",
          }}
        >
          <div
            aria-hidden={!isOpen}
            style={{
              opacity: isOpen && !isFadingOut ? 1 : 0,
              transition: "opacity 1s ease-in-out",
            }}
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </div>
      </div>
    );
  }
}
