import Calendar from "./Calendar/Wrapper";
import ContentTabs from "./ContentTabs/Wrapper";
import ExpandableBox from "./ExpandableBox/Wrapper";
import ImageCallout from "./ImageCallout/";
import ImageGridSlideshow from "./ImageGridSlideshow/Wrapper";
import MiniCalendar from "./MiniCalendar/Wrapper";
import Slideshow from "./Slideshow/";
import FeaturedVideo from "./Video/Featured";
import PastExhibitions from "./PastExhibitions/Wrapper";
import VideoLibrary from "./Video/Library";
import VideoPlayer from "./Video";
import SingleVideo from "./SingleVideo";
import SchoolTourForms from "./SchoolTourForms";

export default {
  Calendar,
  ContentTabs,
  ExpandableBox,
  MiniCalendar,
  Slideshow,
  ImageCallout,
  ImageGridSlideshow,
  PastExhibitions,
  FeaturedVideo,
  VideoPlayer,
  VideoLibrary,
  SingleVideo,
  SchoolTourForms,
};
